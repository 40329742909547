import ReactPlayer from 'react-player';
import { useMediaQuery, useTheme } from '@mui/material';
import PropulseCard from '../Universal/PropulseCard';

function YoutubeVideo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        gap: '3vh',
        margin: '1vh'
      }}
    >
      {[
        'https://www.youtube.com/watch?v=CQllm9cbUIM',
        'https://www.youtube.com/watch?v=bhBINfn6-DA',
        'https://www.youtube.com/watch?v=uqYXnqTVpcU'
      ].map((url, i) => (
        <PropulseCard
          key={i}
          sx={{
            boxShadow: 8,
            transition: '0.5s',
            transitionTimingFunction: 'cubic-bezier(.08,.92,0,1)',
            '&:hover': { transform: 'scale(1.05)' },
            width: isMobile ? '100%' : '48vh',
            height: isMobile ? '22vh' : '27vh'
          }}
        >
          <ReactPlayer url={url} width="100%" height="100%" />
        </PropulseCard>
      ))}
    </div>
  );
}
export default YoutubeVideo;
