import { Box, Button, Container, Fade } from '@mui/material';

import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import Bifrost from './Bifrost';
import Birkeland from './Birkeland';
import Hybrid from './Hybrid';
import Mjolner from './Mjolner';
import Penumbra from './Penumbra';
import Sleipner from './Sleipner';
import Stetind from './Stetind';
import Valemon from './Valemon';

const renderProjectPage = (identifer) => {
  switch (identifer) {
    case 'Valemon':
      return <Valemon />;
    case 'Bifrost':
      return <Bifrost />;
    case 'Birkeland':
      return <Birkeland />;
    case 'Stetind':
      return <Stetind />;
    case 'Mjolner':
      return <Mjolner />;
    case 'Sleipner':
      return <Sleipner />;
    case 'Penumbra':
      return <Penumbra />;
    case 'Hybrid':
      return <Hybrid />;
    default:
      return '';
  }
};

const Project = () => {
  const { name } = useParams();
  return (
    <Fade in>
      <Box
        sx={{
          position: 'relative',
          height: '100dvh'
        }}
      >
        {renderProjectPage(name)}
      </Box>
    </Fade>
  );
};

export default Project;
