import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Fade,
  Grid,
  Typography,
  Tooltip
} from '@mui/material';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import birkelandBackdrop from '../../assets/images/backgrounds/birkeland_backdrop.png';
import bifrostBackdrop from '../../assets/images/backgrounds/bifrost_backdrop.webp';
import mjolnerBackdrop from '../../assets/images/backgrounds/mjolner_backdrop.png';
import sleipnerPatch from '../../assets/images/rocket-projects/mission-patches/sleipner_patch.png';
import penumbraBackdrop from '../../assets/images/backgrounds/penumbra_backdrop.png';
import hybridLogo from '../../assets/images/hybrid/hybridLogo.png';
import sleipnerBackdrop from '../../assets/images/backgrounds/sleipner_backdrop.png';
import stetindBackdrop from '../../assets/images/backgrounds/stetind_backdrop.png';
import valemonBackdrop from '../../assets/images/backgrounds/valemon_backdrop.jpg';
import penumbraLogo from '../../assets/images/penumbra/penumbra_logo.png';
import birkelandPatch from '../../assets/images/rocket-projects/mission-patches/Birkeland_missionPatch.png';
import mjolnerPatch from '../../assets/images/rocket-projects/mission-patches/mjolner_patch.png';
import stetindPatch from '../../assets/images/rocket-projects/mission-patches/stetind_patch.png';
import bifrostPatch from '../../assets/images/rocket-projects/mission-patches/bifrost_patch.png';
import valemonPatch from '../../assets/images/rocket-projects/mission-patches/valemon_patch.png';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

const Projects = () => {
  const [loaded, setLoaded] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = (link) => {
    const timeout = setTimeout(() => {
      navigate(link, { replace: false });
    }, 200);
    return () => clearTimeout(timeout);
  };

  return (
    <>
      <Header
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 1000
        }}
      />
      <Fade in={loaded}>
        <Box sx={{ position: 'relative' }}>
          <Container maxWidth="lg" sx={{ pt: 18 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <Card
                  component={CardActionArea}
                  onClick={() => handleClick('/Projects/Valemon')}
                  disableRipple
                  sx={{
                    boxShadow: 4,
                    width: '100%',
                    height: 400,
                    borderRadius: '20px',
                    position: 'relative',
                    transition: 'ease-in 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    },
                    '&:active': {
                      transform: 'scale(1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={valemonBackdrop}
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      height: '120%',
                      transform: 'scaleX(-1)'
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={valemonPatch}
                    sx={{
                      position: 'absolute',
                      padding: 2,
                      top: '50%',
                      right: { xs: 'initial', md: '-25%' },
                      transform: 'translateY(-50%)',
                      height: '90%',
                      objectFit: 'contain'
                    }}
                  />
                  <CardContent
                    sx={{
                      p: 2,
                      position: 'absolute',
                      top: { xs: 'initial', md: 0 },
                      bottom: { xs: 0, md: 'initial' },
                      width: '100%'
                    }}
                  >
                    <Typography
                      display={{ xs: 'none', md: 'block' }}
                      variant="h2"
                      fontWeight={700}
                      color="white"
                      fontSize={48}
                      sx={{ p: 1 }}
                    >
                      PROJECT <br /> VALEMON <br /> (2024)
                    </Typography>
                    <Typography
                      display={{ xs: 'block', md: 'none' }}
                      variant="h5"
                      fontWeight={700}
                      flexWrap
                      sx={{
                        color: 'white',
                        p: 2,
                        borderRadius: '20px',
                        backdropFilter: 'blur(12px)',
                        width: 'max-content',
                        border: '1px solid #0035f7'
                      }}
                    >
                      PROJECT <br /> VALEMON <br /> (2024)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  component={CardActionArea}
                  onClick={() => handleClick('/Projects/Bifrost')}
                  disableRipple
                  sx={{
                    boxShadow: 4,
                    width: '100%',
                    height: 400,
                    borderRadius: '20px',
                    position: 'relative',
                    transition: 'ease-in 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    },
                    '&:active': {
                      transform: 'scale(1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={bifrostBackdrop}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={bifrostPatch}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      height: '100%',
                      objectFit: 'contain',
                      transform: 'scale(0.85)'
                    }}
                  />
                  <CardContent
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      p: 2
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      flexWrap
                      sx={{
                        color: 'white',
                        p: 2,
                        borderRadius: '20px',
                        backdropFilter: 'blur(12px)',
                        width: 'max-content',
                        border: '1px solid #00DEE7'
                      }}
                    >
                      PROJECT <br /> BIFROST <br /> (2023)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  component={CardActionArea}
                  onClick={() => handleClick('/Projects/Birkeland')}
                  disableRipple
                  sx={{
                    boxShadow: 4,
                    width: '100%',
                    height: 400,
                    background:
                      'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)',
                    borderRadius: '20px',
                    position: 'relative',
                    transition: 'ease-in 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    },
                    '&:active': {
                      transform: 'scale(1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={birkelandBackdrop}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={birkelandPatch}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                  <CardContent
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      p: 2
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      flexWrap
                      sx={{
                        color: '#363636',
                        p: 2,
                        borderRadius: '20px',
                        backdropFilter: 'blur(12px)',
                        width: 'max-content',
                        border: '1px solid #00DEE7'
                      }}
                    >
                      PROJECT <br /> BIRKELAND <br /> (2022)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  component={CardActionArea}
                  onClick={() => handleClick('/Projects/Stetind')}
                  disableRipple
                  sx={{
                    boxShadow: 4,
                    width: '100%',
                    height: 400,
                    background:
                      'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)',
                    borderRadius: '20px',
                    position: 'relative',
                    transition: 'ease-in 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    },
                    '&:active': {
                      transform: 'scale(1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={stetindBackdrop}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={stetindPatch}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                  <CardContent
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      p: 2
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      flexWrap
                      sx={{
                        color: 'white',
                        p: 2,
                        borderRadius: '20px',
                        backdropFilter: 'blur(12px)',
                        width: 'max-content',
                        border: '1px solid #00DEE7'
                      }}
                    >
                      PROJECT <br /> STETIND <br /> (2021)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  component={CardActionArea}
                  onClick={() => handleClick('/Projects/Mjolner')}
                  disableRipple
                  sx={{
                    boxShadow: 4,
                    width: '100%',
                    height: 400,
                    background:
                      'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)',
                    borderRadius: '20px',
                    position: 'relative',
                    transition: 'ease-in 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    },
                    '&:active': {
                      transform: 'scale(1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={mjolnerBackdrop}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={mjolnerPatch}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                  <CardContent
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      p: 2
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      flexWrap
                      sx={{
                        color: '#fff',
                        p: 2,
                        borderRadius: '20px',
                        backdropFilter: 'blur(12px)',
                        width: 'max-content',
                        border: '1px solid #337DFF'
                      }}
                    >
                      PROJECT <br /> MJØLNER <br /> (2020)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  component={CardActionArea}
                  onClick={() => handleClick('/Projects/Sleipner')}
                  disableRipple
                  sx={{
                    boxShadow: 4,
                    width: '100%',
                    height: 400,
                    background:
                      'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)',
                    borderRadius: '20px',
                    position: 'relative',
                    transition: 'ease-in 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    },
                    '&:active': {
                      transform: 'scale(1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={sleipnerBackdrop}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={sleipnerPatch}
                    onLoad={() => setLoaded(true)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                  <CardContent
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      p: 2
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      flexWrap
                      sx={{
                        color: '#fff',
                        p: 2,
                        borderRadius: '20px',
                        backdropFilter: 'blur(12px)',
                        width: 'max-content',
                        border: '1px solid #278EFF'
                      }}
                    >
                      PROJECT <br /> SLEIPNER <br /> (2019)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                md={12}
                xs={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  variant="h2"
                  fontWeight={700}
                  fontSize={54}
                  flexWrap
                  mt={15}
                  mb={5}
                  color="text.primary"
                  sx={{
                    textAlign: 'center',
                    borderRadius: '20px'
                  }}
                >
                  R&D PROJECTS
                </Typography>
                <Box xs={{ bgcolor: 'red', height: '10vh', width: '10vw' }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  component={CardActionArea}
                  onClick={() => handleClick('/Projects/Hybrid')}
                  disableRipple
                  sx={{
                    boxShadow: 4,
                    width: '100%',
                    height: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '20px',
                    position: 'relative',
                    transition: 'ease-in 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    },
                    '&:active': {
                      transform: 'scale(1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={penumbraBackdrop}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={hybridLogo}
                    onLoad={() => setLoaded(true)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      height: '85%',
                      objectFit: 'contain',
                      marginTop: '2%'
                    }}
                  />
                  <CardContent
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      p: 2
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      flexWrap
                      color="text.primary"
                      sx={{
                        color: 'text.primary',
                        p: 2,
                        borderRadius: '20px',
                        backdropFilter: 'blur(12px)',
                        width: 'max-content',
                        border: '1px solid #fc7703'
                      }}
                    >
                      HYBRID <br /> ENGINE <br /> (2019-2022)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  component={CardActionArea}
                  onClick={() => handleClick('/Projects/Penumbra')}
                  disableRipple
                  sx={{
                    boxShadow: 4,
                    width: '100%',
                    height: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '20px',
                    position: 'relative',
                    transition: 'ease-in 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    },
                    '&:active': {
                      transform: 'scale(1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={penumbraBackdrop}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={penumbraLogo}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      height: '95%',
                      objectFit: 'contain',
                      marginTop: '-2%'
                    }}
                  />
                  <CardContent
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      p: 2
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      flexWrap
                      color="text.primary"
                      sx={{
                        color: 'text.primary',
                        p: 2,
                        borderRadius: '20px',
                        backdropFilter: 'blur(12px)',
                        width: 'max-content',
                        border: '1px solid #fc7703'
                      }}
                    >
                      PENUMBRA <br /> Flight Simulation <br /> (2020-2023)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </Box>
      </Fade>
    </>
  );
};

export default Projects;
